const team=[
    {
        name: "Deepika Rajan",
        designation: "CEO",
        imgUrl:"./asset/team/Deepika Rajan.png"
    },
    
    
    {
        name: "Harpinder Singh",
        designation: "Managing Director",
        imgUrl:"./asset/team/Harpinder Singh.png"
    },
    {
        name: "Satyendra Narayan",
        designation: "Brand Manager",
        imgUrl:"./asset/team/Satyam.png"
    },
    {
        name: "Davinder Singh",
        designation: "Marketing Manager",
        imgUrl:"./asset/team/Davinder Singh.png"
    },
    {
        name: "Ramesh Poudel",
        designation: "Office Assistant",
        imgUrl:"./asset/team/Ramesh.png"
    },
    {
        name: "Aashi Gupta",
        designation: "Graphic Designer",
        imgUrl:"./asset/team/Aashi.png"
    },
    {
        name: "Sumit Sharma",
        designation: "Video Editor",
        imgUrl:"./asset/team/Sumit.png"
    },
    {
        name: "Omkar Gawand",
        designation: "Graphic Designer",
        imgUrl:"./asset/team/Omkar.png"
    },
    {
        name: "Sagar Galgale",
        designation: "Graphic Designer",
        imgUrl:"./asset/team/sagernew.jpg"
    },
    
]

export default team;