import React from 'react'
import "./ScrollingText.css";
import { useRef } from "react";
import { motion, useScroll, useSpring, useTransform, useMotionValue, useVelocity, useAnimationFrame } from "framer-motion";
import { wrap } from "@motionone/utils";

function ParallaxText({ children, baseVelocity = 100 }) {
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();
  const scrollVelocity = useVelocity(scrollY);
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400
  });
  const velocityFactor = useTransform(smoothVelocity, [0, 6000], [0, 5], {
    clamp: false
  });

  const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);

  const directionFactor = useRef(1);
  useAnimationFrame((t, delta) => {
    let moveBy = directionFactor.current * baseVelocity * (delta / 6000);

    if (velocityFactor.get() < 0) {
      directionFactor.current = -1;
    } else if (velocityFactor.get() > 0) {
      directionFactor.current = 1;
    }

    moveBy += directionFactor.current * moveBy * velocityFactor.get();

    baseX.set(baseX.get() + moveBy);
  });

  return (
    <div className="parallax">
      <motion.div className="scroller" style={{ x }}>
        <h1>{children}</h1>
        <h1>{children}</h1>
        <h1>{children}</h1>
        <h1>{children}</h1>
        <h1>{children}</h1>
        <h1>{children}</h1>
        <h1>{children}</h1>
      </motion.div>
    </div>
  );
}

export default function Scrollingg() {
  return (
    <section>
         <ParallaxText baseVelocity={-3}>&nbsp; | Top 360 Advertising Solutions / Digital Marketing, Branding, Media Production, Social Media, Influencer Marketing, Web Devlopment & Products Shoots in Navi Mumbai&nbsp;</ParallaxText>
        
    </section>
  )
}
